import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  TableSortLabel
} from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { selectSettings } from 'entities/auth';

export const BaseTable = ({
  columns = [],
  rows = [],
  sorting,
  contextMenuOptions = [],
  ContainerProps,
  getRowProps,
  getHeaderCellProps,
  getCellProps,
  onActionClick,
  onMoreButtonClick,
  onOpenContextMenu,
  onSortingChange
}) => {
  const settings = useSelector(selectSettings);

  const [contextMenu, setContextMenu] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSortingChange = (sortField) => () => {
    onSortingChange({
      ...sorting,
      field: sortField,
      order:
        sortField !== sorting?.field
          ? 'Asc'
          : sorting?.order === 'Asc'
          ? 'Desc'
          : 'Asc'
    });
  };

  const handleRowContextMenu = (row) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!contextMenuOptions.length) return;

    setContextMenu({
      row,
      props: {
        anchorReference: 'anchorPosition',
        anchorPosition: { left: event.clientX, top: event.clientY }
      }
    });

    const docHandle = (event) => {
      event.preventDefault();

      setContextMenu(null);

      document.removeEventListener('contextmenu', docHandle);
    };

    document.addEventListener('contextmenu', docHandle);

    onOpenContextMenu?.(event, row);
  };

  const handleRowMoreClick = (row) => (event) => {
    setContextMenu({
      row,
      props: {
        anchorEl: event.currentTarget,
        anchorReference: 'anchorEl',
        transformOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        }
      }
    });

    onMoreButtonClick?.(event, row);
  };

  return (
    <>
      <TableContainer {...ContainerProps}>
        <Table stickyHeader>
          <TableHead sx={{ display: isMobile ? 'none' : '' }}>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align ?? 'inherit'}
                  {...getHeaderCellProps?.(index)}
                  sx={{
                    ...(getHeaderCellProps?.(index)?.sx ?? {}),
                    width: column.width ?? null
                  }}
                >
                  {!column.sortField ? (
                    column.title
                  ) : (
                    <TableSortLabel
                      active={sorting?.field === column.sortField}
                      // IconComponent={ArrowUpward}
                      direction={
                        sorting?.field === column.sortField
                          ? sorting?.order?.toLowerCase()
                          : 'asc'
                      }
                      onClick={handleSortingChange(column.sortField)}
                    >
                      {column.title}
                      {sorting?.field === column.sortField ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting?.order?.toLowerCase() === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              {!!contextMenuOptions.length && <TableCell align="right" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onContextMenu={handleRowContextMenu(row)}
                {...getRowProps?.(row, rowIndex)}
              >
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      display: isMobile && index > 0 ? 'none' : '',
                      paddingLeft: isMobile ? 1 : '',
                      paddingRight: isMobile ? 1 : '',
                      paddingTop: isMobile ? 2 : '',
                      paddingBottom: isMobile ? 2 : ''
                    }}
                    {...getCellProps?.({ row, rowIndex, index })}
                  >
                    {column.render({ row, rowIndex, index, onActionClick, timeZone: settings?.Timezone?.Name ? settings?.Timezone?.Name : 'UTC' }) ??
                      '-'}
                  </TableCell>
                ))}
                {!!onActionClick && (
                  <TableCell
                    align="right"
                    sx={{
                      padding: isMobile ? 1 : '',
                      width: isMobile ? 50 : ''
                    }}
                  >
                    <IconButton
                      size={isMobile ? 'small' : 'medium'}
                      onClick={handleRowMoreClick(row)}
                    >
                      <MoreVertOutlined />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!contextMenuOptions.length && (
        <Menu
          open={!!contextMenu?.props}
          onClose={() => setContextMenu(null)}
          {...contextMenu?.props}
        >
          {contextMenuOptions.map((option, index) =>
            option.name === 'divider' ? (
              <Divider key={index} />
            ) : (
              <MenuItem
                key={index}
                sx={{ minHeight: isMobile ? 40 : '' }}
                onClick={() => {
                  onActionClick?.({
                    name: option.name,
                    payload: contextMenu.row
                  });

                  setContextMenu(null);
                }}
              >
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  );
};
